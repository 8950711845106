@media screen and (min-width: 825px) {
  .music-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #b11e1e;
    padding-top: 20px;
    margin-top: 25px;
  }
  .page-link {
    text-decoration: none;
    color: #d41d1d;
    font-family: Bright;
    font-size: 44px;
    margin-left: 15px;
    height: fit-content;
  }
  .top-navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #d41d1d;
    padding-top: 0;
    position: sticky;
    margin: 0;
    z-index: 999;
    top: 0;
    background-color: #ffa30d;
  }
  #image-logo {
    width: 200px;
  }
  .ali-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aliLogo {
    width: 200px;
    height: 200px;
  }
  .streaming-services-container {
    position: relative;
    padding-bottom: 175px;
  }
  .streaming-logo {
    border-radius: 1000px;
    padding: 30px;
    width: fit-content;
    height: 60px;
    width: 60px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    text-decoration: none;
  }
  .spotify {
    /* top: 75px;
    left: -110px; */
    /* top: 75px;
    left: -110px; */
    top: -30px;
    right: 0px;
    text-decoration: none;
  }
  .apple {
    /* top: 75px;
    left: 15px; */
    top: -30px;
    right: -130px;
  }
  .youtube {
    /* top: -30px;
    right: -200px; */
    top: 85px;
    left: -190px;
  }
  .soundcloud {
    /* top: -30px;
    right: -75px; */
    top: 85px;
    left: -60px;
  }
  .amazon {
    /* top: -30px;
    right: 50px; */
    top: 85px;
    left: 70px;
  }
  .fa-spotify {
    color: #1ed760;
    margin-top: -10px;
    display: flex;
    justify-content: center;
  }
  .fa-apple {
    margin-top: -15px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .fa-youtube {
    margin-top: -10px;
    color: red;
    display: flex;
    justify-content: center;
  }
  .fa-soundcloud {
    color: #ff5500;
    margin-top: -15px;
    display: flex;
    justify-content: center;
  }
  .fa-amazon {
    margin-top: -10px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .loading-video-div {
    background-color: black;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  #iframe-embed {
    width: 800px;
    height: 450px;
  }
  .about-me-paragraph {
    color: wheat;
    width: 80%;
  }
  /* style={{ paddingBottom: "50px", marginTop: "40px", display: "flex", flexDirection: "row", width: "100%", backgroundColor: "#ffa30d", borderTop: "1px solid #d41d1d", justifyContent: "center", alignItems: "center" }} */
  .bottom-nav-container {
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    background-color: #ffa30d;
    border-top: 1px solid #d41d1d;
  }
  .logo-image-music-nav {
    width: 150px;
    height: 105px;
  }
}
@media screen and (max-width: 844px) and (min-width: 675px) {
  /* body {
    background-color: #b11e1e;
  } */
  .page-link {
    text-decoration: none;
    color: #d41d1d;
    font-family: Bright;
    font-size: 44px;
    margin-left: 15px;
    height: fit-content;
  }
  .music-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #b11e1e;
  }
  #image-logo {
    width: 200px;
  }
  .ali-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aliLogo {
    width: 200px;
    height: 200px;
  }
  .streaming-services-container {
    position: relative;
    padding-bottom: 175px;
  }
  .streaming-logo {
    /* border: 1px solid white; */
    border-radius: 1000px;
    padding: 30px;
    width: fit-content;
    height: 60px;
    width: 60px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    text-decoration: none;
  }
  .spotify {
    /* top: 75px;
    left: -110px; */
    /* top: 75px;
    left: -110px; */
    top: -30px;
    right: 0px;
    text-decoration: none;
  }
  .apple {
    /* top: 75px;
    left: 15px; */
    top: -30px;
    right: -130px;
  }
  .youtube {
    /* top: -30px;
    right: -200px; */
    top: 85px;
    left: -190px;
  }
  .soundcloud {
    /* top: -30px;
    right: -75px; */
    top: 85px;
    left: -60px;
  }
  .amazon {
    /* top: -30px;
    right: 50px; */
    top: 85px;
    left: 70px;
  }
  .fa-spotify {
    color: #1ed760;
    margin-top: -10px;
    display: flex;
    justify-content: center;
  }
  .fa-apple {
    margin-top: -15px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .fa-youtube {
    margin-top: -10px;
    color: red;
    display: flex;
    justify-content: center;
  }
  .fa-soundcloud {
    color: #ff5500;
    margin-top: -15px;
    display: flex;
    justify-content: center;
  }
  .fa-amazon {
    margin-top: -10px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .loading-video-div {
    background-color: black;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  #iframe-embed {
    width: 650px;
    height: 400px;
  }
  .about-me-paragraph {
    color: wheat;
    width: 90%;
  }
  .bottom-nav-container {
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    background-color: #ffa30d;
    border-top: 1px solid #d41d1d;
  }
  .logo-image-music-nav {
    width: 150px;
    height: 115px;
  }
  .logo-image-music-nav {
    width: 140px;
    height: 110px;
  }
}
@media screen and (max-width: 674px) and (min-width: 531px) {
  /* body {
    background-color: #b11e1e;
  } */
  .page-link {
    text-decoration: none;
    color: #d41d1d;
    font-family: Bright;
    font-size: 44px;
    margin-left: 15px;
    height: fit-content;
  }
  .music-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #b11e1e;
  }
  #image-logo {
    width: 200px;
  }
  .ali-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aliLogo {
    width: 200px;
    height: 200px;
  }
  .streaming-services-container {
    position: relative;
    padding-bottom: 175px;
  }
  .streaming-logo {
    /* border: 1px solid white; */
    border-radius: 1000px;
    padding: 30px;
    width: fit-content;
    height: 60px;
    width: 60px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    text-decoration: none;
  }
  .spotify {
    /* top: 75px;
    left: -110px; */
    /* top: 75px;
    left: -110px; */
    top: -30px;
    right: 0px;
    text-decoration: none;
  }
  .apple {
    /* top: 75px;
    left: 15px; */
    top: -30px;
    right: -130px;
  }
  .youtube {
    /* top: -30px;
    right: -200px; */
    top: 85px;
    left: -190px;
  }
  .soundcloud {
    /* top: -30px;
    right: -75px; */
    top: 85px;
    left: -60px;
  }
  .amazon {
    /* top: -30px;
    right: 50px; */
    top: 85px;
    left: 70px;
  }
  .fa-spotify {
    color: #1ed760;
    margin-top: -10px;
    display: flex;
    justify-content: center;
  }
  .fa-apple {
    margin-top: -15px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .fa-youtube {
    margin-top: -10px;
    color: red;
    display: flex;
    justify-content: center;
  }
  .fa-soundcloud {
    color: #ff5500;
    margin-top: -15px;
    display: flex;
    justify-content: center;
  }
  .fa-amazon {
    margin-top: -10px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .loading-video-div {
    background-color: black;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  #iframe-embed {
    width: 450px;
    height: 400px;
  }
  .about-me-paragraph {
    color: wheat;
    width: 90%;
  }
  .bottom-nav-container {
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    background-color: #ffa30d;
    border-top: 1px solid #d41d1d;
  }
  .logo-image-music-nav {
    width: 140px;
    height: 110px;
  }
}
@media screen and (max-width: 530px) and (min-width: 401px) {
  /* body {
    background-color: #b11e1e;
  } */
  .page-link {
    text-decoration: none;
    color: #d41d1d;
    font-family: Bright;
    font-size: 44px;
    margin-left: 15px;
    height: fit-content;
  }
  .music-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #b11e1e;
  }
  #image-logo {
    width: 200px;
  }
  .ali-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aliLogo {
    width: 200px;
    height: 200px;
  }
  .streaming-services-container {
    position: relative;
    padding-bottom: 175px;
    margin-left: -15px;
  }
  .streaming-logo {
    /* border: 1px solid white; */
    border-radius: 1000px;
    padding: 30px;
    width: fit-content;
    height: 60px;
    width: 60px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    text-decoration: none;
  }
  .spotify {
    /* top: 75px;
    left: -110px; */
    /* top: 75px;
    left: -110px; */
    top: -30px;
    right: 0px;
    text-decoration: none;
  }
  .apple {
    /* top: 75px;
    left: 15px; */
    top: -30px;
    right: -130px;
  }
  .youtube {
    /* top: -30px;
    right: -200px; */
    top: 85px;
    left: -190px;
  }
  .soundcloud {
    /* top: -30px;
    right: -75px; */
    top: 85px;
    left: -60px;
  }
  .amazon {
    /* top: -30px;
    right: 50px; */
    top: 85px;
    left: 70px;
  }
  .fa-spotify {
    color: #1ed760;
    margin-top: -10px;
    display: flex;
    justify-content: center;
  }
  .fa-apple {
    margin-top: -15px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .fa-youtube {
    margin-top: -10px;
    color: red;
    display: flex;
    justify-content: center;
  }
  .fa-soundcloud {
    color: #ff5500;
    margin-top: -15px;
    display: flex;
    justify-content: center;
  }
  .fa-amazon {
    margin-top: -10px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .loading-video-div {
    background-color: black;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  #iframe-embed {
    width: 350px;
    height: 400px;
  }
  .about-me-paragraph {
    color: wheat;
    width: 90%;
  }
  .bottom-nav-container {
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    background-color: #ffa30d;
    border-top: 1px solid #d41d1d;
  }
  .logo-image-music-nav {
    width: 130px;
    height: 100px;
  }
}
@media screen and (max-width: 400px) {
  /* body {
    background-color: #b11e1e;
  } */
  .page-link {
    text-decoration: none;
    color: #d41d1d;
    font-family: Bright;
    font-size: 44px;
    margin-left: 15px;
    height: fit-content;
  }
  .music-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #b11e1e;
  }
  #image-logo {
    width: 200px;
  }
  .ali-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aliLogo {
    width: 150px;
    height: 150px;
  }
  .streaming-services-container {
    position: relative;
    padding-bottom: 175px;
    margin-right: 30px;
  }
  .streaming-logo {
    /* border: 1px solid white; */
    border-radius: 1000px;
    padding: 30px;
    width: fit-content;
    height: 45px;
    width: 45px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    text-decoration: none;
  }
  .spotify {
    /* top: 75px;
    left: -110px; */
    /* top: 75px;
    left: -110px; */
    top: -30px;
    right: -5px;
    text-decoration: none;
    font-size: 16px;
  }
  .apple {
    /* top: 75px;
    left: 15px; */
    top: -30px;
    right: -115px;
  }
  .youtube {
    /* top: -30px;
    right: -200px; */
    top: 85px;
    left: -150px;
    font-size: 14px;
  }
  .soundcloud {
    /* top: -30px;
    right: -75px; */
    top: 85px;
    left: -40px;
    font-size: 14px;
  }
  .amazon {
    /* top: -30px;
    right: 50px; */
    top: 85px;
    left: 70px;
    font-size: 14px;
  }
  .fa-spotify {
    color: #1ed760;
    margin-top: -17px;
    display: flex;
    justify-content: center;
  }
  .fa-apple {
    margin-top: -20px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .fa-youtube {
    margin-top: -10px;
    color: red;
    display: flex;
    justify-content: center;
  }
  .fa-soundcloud {
    color: #ff5500;
    margin-top: -15px;
    display: flex;
    justify-content: center;
  }
  .fa-amazon {
    margin-top: -10px;
    color: black;
    display: flex;
    justify-content: center;
  }
  .loading-video-div {
    background-color: black;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  #iframe-embed {
    width: 350px;
    height: 400px;
  }
  .about-me-paragraph {
    color: wheat;
    width: 90%;
  }
  .bottom-nav-container {
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    background-color: #ffa30d;
    border-top: 1px solid #d41d1d;
    height: 100%;
  }
  .logo-image-music-nav {
    height: 80px;
    width: 100px;
  }
}

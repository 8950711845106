@keyframes rainbow {
  0% {
    background-color: rgb(164, 51, 51);
  }
  25% {
    background-color: rgb(70, 70, 236);
  }
  50% {
    background-color: rgb(76, 159, 76);
  }
  75% {
    background-color: rgb(218, 151, 27);
  }
  100% {
    background-color: rgb(164, 51, 51);
  }
}
@media screen and (min-width: 1100px) {
  .home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: wheat;
    height: 100vh;
    width: 100%;
    background-color: rgb(164, 51, 51);
    transition: background-color linear;
    animation: rainbow 10s infinite;
  }

  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 12%; */
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Bright;
    font-size: 55px;
    margin-top: 20px;
  }

  .music-link {
    color: wheat;
    text-decoration: none;
  }

  .donations-link {
    color: wheat;
    text-decoration: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: default;
  }
  #title-span {
    font-size: 80px;
    font-family: Bright;
  }
  .home-logo-image {
    width: 250px;
  }
  #home-image {
    width: 300px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 1099px) and (min-width: 1005px) {
  .home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: wheat;
    height: 100vh;
    width: 100%;
    background-color: rgb(164, 51, 51);
    transition: background-color linear;
    animation: rainbow 10s infinite;
  }

  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 12%; */
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Bright;
    font-size: 50px;
    margin-top: 20px;
  }

  .music-link {
    color: wheat;
    text-decoration: none;
  }

  .donations-link {
    color: wheat;
    text-decoration: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: default;
  }
  #title-span {
    font-size: 70px;
    font-family: Bright;
  }
  .home-logo-image {
    width: 250px;
  }
  #home-image {
    width: 250px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 1004px) and (min-width: 876px) {
  .home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: wheat;
    height: 100vh;
    width: 100%;
    background-color: rgb(164, 51, 51);
    transition: background-color linear;
    animation: rainbow 10s infinite;
  }

  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 12%; */
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Bright;
    font-size: 50px;
    margin-top: 20px;
  }

  .music-link {
    color: wheat;
    text-decoration: none;
  }
  .donations-link {
    color: wheat;
    text-decoration: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: default;
  }
  #title-span {
    font-size: 66px;
    font-family: Bright;
  }
  .home-logo-image {
    width: 250px;
  }
  #home-image {
    width: 200px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 875px) {
  .home-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: wheat;
    height: 100vh;
    width: 100%;
    background-color: rgb(164, 51, 51);
    transition: background-color linear;
    animation: rainbow 10s infinite;
  }

  .middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 12%; */
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Bright;
    font-size: 50px;
    margin-top: 20px;
  }

  .music-link {
    color: wheat;
    text-decoration: none;
  }
  .donations-link {
    color: wheat;
    text-decoration: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    cursor: default;
  }
  #title-span {
    font-size: 55px;
    font-family: Bright;
    width: 300px;
    display: flex;
    justify-content: center;
  }
  .home-logo-image {
    width: 250px;
  }
  #home-image {
    width: 200px;
    border-radius: 20px;
    display: none;
  }
}

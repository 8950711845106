body{
  font-family: Tourney;
  text-shadow: '3px 3px 2px black';
  background-color: #009736;
}
.top-navbar-donations {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #d41d1d;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: white;
}
.logo-image {
  width: 150px;
}
.main-page-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* margin-bottom: 300px; */
}
h1 {
  font-size: 40px;
  text-shadow: 2px 3px 2px black;

  color: #f5deb3;
}
.buy-button{
  margin-top: 5px;
  font-family: Tourney;
  text-shadow: 2px 3px 2px black;
  font-size: 48px;
  color: black;
  width: 350px;
  cursor: pointer;
  background-color: #EE2A35;
  border: none;
  /* height: 35px; */
  border-radius: 50px;
  /* color: #b11e1e; */
  /* font-size: 25px; */
  /* font-family: Bright; */
  padding: 25px;
}
.donate-button{
    margin-top: 5px;
    font-family: Tourney;
    /* text-shadow: 2px 3px 2px black; */
    font-size: 48px;
    color: black;
    width: 350px;
    cursor: pointer;
    background-color: #EE2A35;
    border: none;
    /* height: 35px; */
    border-radius: 50px;
    /* color: #b11e1e; */
    /* font-size: 25px; */
    /* font-family: Bright; */
    /* padding: 25px; */
    height: 100px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}
#main-donate-button {
  margin-top: 5px;
  font-family: Tourney;
  text-shadow: 2px 3px 2px black;
  font-size: 48px;
  color: black;
  width: 350px;
  cursor: pointer;
  background-color: #EE2A35;
  border: none;
  /* height: 35px; */
  border-radius: 5px;
  /* color: #b11e1e; */
  /* font-size: 25px; */
  /* font-family: Bright; */
  padding: 25px;
}
#small-donate-button{
  margin-top: 5px;
  width: 60px;
  cursor: pointer;
  border: none;
  /* height: 35px; */
  border-radius: 5px;
  /* color: #b11e1e; */
  /* font-size: 25px; */
  /* font-family: Bright; */
  padding: 25px;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-product {
  width: 350px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  color:#f3d9af;
  text-align: center;
}
.crsl {
  width: 300px;
  margin: auto;
}
.carousel-status {
  visibility: hidden;
}
.thumbs {
  visibility: hidden;
}
#pcrf{
  width: 150px;
  height: 50px;
  margin-bottom: 20px;
}
